<template>
    <section class="home_section">
        <h1 class="home_title">最愛清單</h1>
        <div class="favorite_lists">
            <div v-for="favorite_list in favorite_lists" :key="favorite_list.id" class="favorite_list_name"
                @click="navigateToFavoriteList(favorite_list)">{{ favorite_list.name }}</div>
        </div>
        <h1 class="home_title">股票策略紀錄</h1>
        <div class="strategy_lists">
            <a class="strategy_list" href="/strategy_records/TW/">台股股票策略紀錄</a>
            <a class="strategy_list" href="/strategy_records/US/">美股股票策略紀錄</a>
        </div>
    </section>
</template>



<script>
import axios from 'axios'

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            favorite_lists: [],
        }
    },
    mounted() {
        this.getFavoriteLists()
    },
    methods: {
        navigateToFavoriteList(favorite_list) {
            if (favorite_list.region?.value === 'TW') {
                this.$router.push({ name: 'tw_favorite_list', params: { favorite_list_id: favorite_list.id } });
            } else if (favorite_list.region?.value === 'US') {
                this.$router.push({ name: 'us_favorite_list', params: { favorite_list_id: favorite_list.id } });
            }
        },
        async getFavoriteLists() {
            const favorite_list_api_url = `/api/v1/favorite_list/?no_pagination=true`
            await axios
                .get(favorite_list_api_url)
                .then((response) => {
                    this.favorite_lists = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    }
}
</script>

<style lang="scss">

.home_title {
    color: #248F77;
    font-weight: normal;
    font-size: 1.2rem;
}

.home_section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.favorite_lists {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
}

.favorite_list_name {
    background-color: #487eb0;
    padding: 0.5rem 0.8rem;
    margin-right: 0.5rem;
    border-radius: 5px;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    text-decoration: none;
}

.strategy_list {
    background-color: #487eb0;
    padding: 0.5rem 0.8rem;
    margin-right: 0.5rem;
    border-radius: 5px;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    text-decoration: none;
}

</style>
