import { createStore } from 'vuex'

export default createStore({
    state: {
        isStaff: false,
        isAuthenticated: false,
        token: '',
        user: {
            id: 0,
            username: ''
        },
    },
    getters: {
        isStaff(state) {
            return state.isStaff
        },
        isLoggedIn(state) {
            return state.isAuthenticated
        },
        getUserName(state) {
            return state.user.username
        }
    },
    mutations: {
        initializeStore(state) {
            if (localStorage.getItem('token')) {
                state.isStaff = localStorage.getItem('isStaff') == 'true'
                state.token = localStorage.getItem('token')
                state.user = {
                    username: localStorage.getItem('username')
                }
                state.isAuthenticated = true
            } else {
                state.isStaff = false
                state.token = ''
                state.isAuthenticated = false
            }
        },
        setIsStaff(state, isStaff) {
            state.isStaff = isStaff
        },
        setUser(state, user) {
            state.user = user
        },
        setToken(state, token) {
            state.token = token
            state.isAuthenticated = true
        },
        removeToken(state) {
            state.token = ''
            state.isAuthenticated = false
        },
    },
    actions: {
    },
    modules: {
    }
})
